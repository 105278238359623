import { createGlobalState } from 'react-hooks-global-state'

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  alert: { show: false, msg: '', color: '' },
  loading: { show: false, msg: '' },
  contract: null,
  maxSupply: 10,
  amountMinted: "?",
  connectedAccount: '',
  nfts: [],
  whitelistAddresses: [[
    "0x2b9be69f6c217795793a7b1b79f3adbe64aad636","0x697ed6dcdb5d3781f043571d912c9cb29ad01b58","0xbeeb460081c1e3820a6afaa309c6d3c45f465335","0xc58d4fa33ee60dc606544f23720150b2080bf3ce","0xe4e8d6650a8dc5ebf91f9ee563d71378483d90c3","0x11b58635f9552aece80bcf7fbc7f7910a5fec715","0x5ce5892fb75f63fb3c700ffe2ac0bb18f53da80a","0x864d7390153b07f0d826db49470441c3c791818e","0x9c6f3006085575d711a2b37f639047e9232c1759","0xa92e8d7667faf4527476d12e042399607b974637","0xc785fd4e9aee80a6d6cbfcc57380b92aa92deef3","0xd46b94375251d1a6caebae37953eaf2177e6de52","0xda2007810a38d939fffc1a78e3ba53cd604a544e","0x32ec773ed7b5a54d1f0ac77944caf76ed30e6b86","0x538770dc4eba4a7d884ae1b62c5a44690259bcd0","0x5f4ef5c539a56291665fcca4e6b7a6446ad11cc1","0x74667801993b457b8ccf19d03bbbaa52b7fff43b","0x7933231775dec3f3c80039debd7e3afd8a81f674","0x83b61abd789bba9cdbef56dad18d6f2d1e826406","0x934dc7a07441d4f686d1d0e3e7580ec6eef19f6d","0x96acf191c0112806f9709366bad77642b99b21a9","0xbec2635b44d26acf54dda59644ddb71fb0e0268d","0xcf8e7573894cb97d2589efdaf04cf65b8ad94cd9","0xd5481575130a7decf4503c81a50152b3753031f9","0xe055721b972d58f0bcf6370c357879fb3a37d2f3","0xe1da9e3ea9efc074ebffd4d2bed209b370705188","0xe247e85441308f45a1c581072cf12671405c1a64","0xe3f952fc4e698c1d0c89ac754b903f101a9b0a14","0xed22bb0106c24c7f6b4d8aae33639e1467061f64","0xf402b440ea22ae602fb0f22f314bc6013adc0284","0x8028c92E49543fa7aF3a1541d9D07f22139cB631"
  ], [
      "0x041526c8dce0d36e04d4668c1f3d425cb9548729","0x703f87c50d915775228e40a0f562552e291e5540","0x7b753919b953b1021a33f55671716dc13c1eae08"
  ], [
      "0x3f946597157380707d82b6720a3a6f92a7873263","0xde0cbd5df89bb67ab804db21e9b931bad4200392","0x8ac563233ba5ed8b5f0f03260b114a1a5def1208"
  ], [
      "0x58452efeabc31eef4f74ae0002fac6cb03c1229e"
  ]],
    contractAddress: "0xBE4ceB1045bF54c6E661C181311E19C4B0256468"
  })

const setAlert = (msg, color = 'green') => {
  setGlobalState('alert', { show: true, msg, color })
  setTimeout(() => {
    setGlobalState('alert', { show: false, msg: '', color })
    setGlobalState('loading', false)
  }, 8000)
}

const setLoadingMsg = (msg) => {
  const loading = getGlobalState('loading')
  setGlobalState('loading', { ...loading, msg })
}

export {
  useGlobalState,
  setGlobalState,
  getGlobalState,
  setAlert,
  setLoadingMsg,
}
