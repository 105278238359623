
import {
    useGlobalState,
    setGlobalState,
} from '../store'
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import React, { useState, useEffect } from "react";

import {
    useContractWrite,
    useWaitForTransaction,
    usePrepareContractWrite,
    useContractRead,
    useAccount
} from 'wagmi';
import contractABI from '../contract-abi.json';
contractABI = contractABI['abi']

//import { MerkleTree } from "merkletreejs";
//import keccak256 from "keccak256";

const Wrapper = ()=>{
    var { address } = useAccount()
    const [whitelistAddresses] = useGlobalState('whitelistAddresses')
    const [amountMinted] = useGlobalState('amountMinted')
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    const [contractAddress] = useGlobalState('contractAddress')
    const [reason, setReason] = useState();

    useEffect(() => {
        setIsPageLoaded(true)
    }, []);

    useEffect(() => {
        setIsPageLoaded(true);
    }, [isPageLoaded]);

    let white_list = []

    console.log('in wrraper', address)
    if(address){
        address = address.toLowerCase();   
    }

    for (let i = 0; i < whitelistAddresses.length; i++) {
        if (whitelistAddresses[i].includes(address)) {
            white_list = whitelistAddresses[i]
        }
    }

    let leafNodes = white_list.map(addr => keccak256(addr));

    if(leafNodes.length>0){
        let merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
        let rootHash = merkleTree.getRoot();
        let rootHashBytes32 = "0x" + merkleTree.getRoot().toString("hex");
        var claimingAddress = leafNodes[white_list.indexOf(address)];
        const hexProof = merkleTree.getHexProof(claimingAddress);

        var keyInfo = {
            claimingAddress: claimingAddress,
            hexProof: hexProof,
            rootHashBytes32: rootHashBytes32
        }

        return <MintButton myData={keyInfo}/>
    } 
    else{
        return <p>Address not found on the Saint list</p>
    }
}


const MintButton = (myData) => {
    const { address } = useAccount()
    const [whitelistAddresses] = useGlobalState('whitelistAddresses')
    const [amountMinted] = useGlobalState('amountMinted')
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    const [contractAddress] = useGlobalState('contractAddress')
    const [reason, setReason] = useState();
    const [minted, setMinted] = useState(false);
    const [minting, setMinting] = useState(false);
    const [open, setOpen] = useState()

    useEffect(() => {
        setIsPageLoaded(true)
    }, []);

    useEffect(() => {
        setIsPageLoaded(true);
    }, [isPageLoaded]);


    let claimingAddress = myData['myData']['claimingAddress']
    let hexProof = myData['myData']['hexProof']
    let rootHashBytes32 = myData['myData']['rootHashBytes32']

    var string = new TextDecoder().decode(claimingAddress);



    const { config, error } = usePrepareContractWrite({
        addressOrName: contractAddress,
        contractInterface: contractABI,
        functionName: "mintWL",
        args:
            [hexProof, rootHashBytes32],
        overrides: { value: 100000001 },
        onError(error) {
            console.log('set error usePrepareContractWrite', error, typeof error)
            setReason(error.reason)
            setReady(false)
        },
        onSuccess(data) {
          console.log('Success usePrepareContractWrite', data)
          setReady(true)
        }
    })

    const { data, isLoading, isSuccess, write: mintWL } = useContractWrite(config,
            {
                onMutate(args, overrides) {
                    console.log("mutate")
                },
                onError() {
                    console.log("error")
                },
                onSettled() {
                    console.log("done")
                }
            })


    const { isLoading2, isSuccess2 } = useWaitForTransaction({
        hash: data?.hash,
    })

    const waitForTransaction = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
          console.log('Wait Success', data)
        },
        onSettled() {
            setGlobalState('loading', { show: false, msg: '' })
            console.log('finished minting')
            setMinted(true)
        },
        onError() {
            console.log("error")
        }
    })

    var reasons = {
        'insufficient funds for intrinsic transaction cost': 'Please ensure you have funds in your connected wallet',
        'execution reverted: Invalid merkle proof': 'Address not found on the Saint list',
        'execution reverted: You cant mint any more NFTs': 'Mint limit reached',
        "execution reverted: The mint hasn't started yet":  'Minting not started'
    }

    if(reason){
        var message = reasons[reason]
        if(message == null){
            message = 'Error'
        }
    }

    var button = <button
            className="shadow-xl shadow-black text-white
            bg-[#e32970] hover:bg-[#bd255f] p-2
            rounded-full cursor-pointer my-4"
            onClick={() => {
                mintWL()
            }}
        >
            Mint Now
        </button >

    if(minted) {
        button = <button>
        Mint Complete
        </button>    
    }
    else if(isSuccess){
        button = <button>
        Minting
        </button>
    }
    else if(isLoading){
        button = <button>
        Confirm Transaction
        </button>
    }
    return (
        <>
        {ready ? button : <></>}
        {reason && !ready ? <p>{message}</p> : <></>}
        </>

    )
}// make amount minted read from smartcontract

export default Wrapper