import avatar from '../assets/saintrien.jpg'
import github from '../assets/github_icon.png'
import facebook from '../assets/facebook_icon.png'
import twitter from '../assets/twitter_icon.png'
import linkedIn from '../assets/linkedIn_icon.png'
import medium from '../assets/medium_icon.png'
import Header from './Header'

import {
  useGlobalState,
} from '../store'
import MintButton from './MintButton'
import { ethers } from 'ethers'

import React, {useEffect} from 'react';


import {
    useContractWrite,
    useContractRead,
    useWaitForTransaction,
    usePrepareContractWrite,
    useAccount
} from 'wagmi';
import contractABI from '../assets/contract-abi.json';
contractABI = contractABI['abi']


const Hero = (props) => {
  const [contractAddress] = useGlobalState('contractAddress')
  const { address } = useAccount()

 

  if(!contractAddress || !contractABI){
    return <h1>Loading</h1>
  }
  else{


    return <div className="main">
      <img id="oldFilm" src="https://thumbs.gfycat.com/ShrillPowerfulAlbertosaurus-size_restricted.gif"/>
      <Header />
      

      <div className="inner">

        <div className="mainSec">
        <h1>Les Pétales de Saint Rien</h1>
        <p>A token of appreciation, courtesy of Monsieur Rien, complimentary for supporters of Le Culte de Saint Rien. Max supply of 1,111 but do not be late, Monsieur and his acquaintances are known to reward those that are early.
        <br/>
        </p>
        </div>

        <div className="mainSec nftSec">
          <div className="preview">
            <img src="roseb2.gif" alt="loading..." id="roseVideo"/>
          </div>
          <div className="buttonCont">
          {!props.address || !address ? <p>No wallet connected</p> : 
          <MintButton address={props.address.toLowerCase()}/>
          }
          </div>
        </div>
      </div>
      <p id="footer">a <a href="https://twitter.com/volrecords_">Vol Records</a> project</p>
    </div>    
  }


}

export default Hero


/* minting process:
if not whitelisted:
- mint button changes
- countdown for normal mint
else:
- get proof

when mint pressed:
- error handeling for metamask
- error handeling for anything else
*/